<template>
    <div class="vue-template">
      <div class="vertical-center jumbotron">
        404 not found
      </div>
    </div>
</template>
<script>
  export default {
    created: function(){
      this.$alertify({
        group: 'notification',
        title: 'Error 404 - Redirecting',
        type: 'error',
        text: 'The page you entered does not exist.'
      })
      this.$router.push('/')
    }
  }
</script>
